<template>
  <!-- 担保公司 -->
  <div class="guaranteeCompanyBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" class="form_Search" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addRow">
          新增担保公司
        </el-button>
      </div>

      <Table
        :operation-button-width="236"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
      />
      <!-- 弹出的新增跟修改框 -->
      <Dialog
        ref="addAndMoneySide"
        dialog-width="35%"
        label-width="140px"
        :edit-form-data="editFormData"
        :form-item="addAndEditFormItem"
        @getFormData="getFormData"
      />

      <!-- 分页  -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { addAndGuaranteeCompany, obtainGuaranteeCompany, deleteGuaranteeCompany, putGuaranteeCompany } from '@/api/guaranteeCompany'
import { rules } from '@/utils/matchFormRegex'

export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      warehouseList: [],
      forwarder: {
        formInline: {
          pageSize: 10,
          pageNum: 1
        },
        total: 0,
        listData: []
      },
      itemData: [
        { label: '担保公司名称', prop: 'guaranteeCmpName' },
        { label: '统一社会信用代码', prop: 'guaranteeCmpUnicode' },
        { label: '联系人', prop: 'contract' },
        { label: '联系电话', prop: 'contractPhone' }
      ],
      formItemArr: [
        { type: 'input', label: '担保公司', value: 'guaranteeCmpName' },
        { type: 'input', label: '统一社会信用代码', value: 'guaranteeCmpUnicode' }
      ],
      loading: false,
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.addRow },
        { bType: 'primary', label: '删除', handleEvent: this.deleteFormData }
      ],
      editFormData: {},
      addAndEditFormItem: [
        {
          type: 'input',
          label: '担保公司名称',
          value: 'guaranteeCmpName',
          disabled: false,
          rules: [
            { required: true, message: '请输入', trigger: 'blur' },
            {
              min: 1,
              max: 25,
              message: '长度在 1 到 25 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '统一社会信用代码',
          value: 'guaranteeCmpUnicode',
          rules: [...rules.unicode]
        },
        {
          type: 'select',
          label: '银行类型',
          value: 'bankCode',
          optionLabel: 'dictName',
          optionId: 'dictId',
          child: this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
        },
        {
          type: 'input',
          label: '联系人',
          value: 'contract',
          rules: [
            { required: true, message: '请输入', trigger: 'blur' },
            {
              min: 1,
              max: 15,
              message: '长度在 1 到 15 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '联系电话',
          value: 'contractPhone',
          rules: [...rules.phone]
        }
      ],
      // 表格数据
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0
    }
  },
  methods: {
    // 列表  获取数据
    // getForwarderData() {
    //   if (!type) {
    //     this.forwarder.formInline.pageNum = 1;
    //   }
    // },
    // 添加 - 编辑  确认按钮
    getFormData(value) {
      if (value.id) {
        putGuaranteeCompany(value, res => {
          this.editFormData = value
          this.$message.success('修改成功')
          this.$refs.addAndMoneySide.editFormVisible = false
          this.getdata()
        })
      } else {
        addAndGuaranteeCompany(value, () => {
          this.$message.success('添加成功')
          this.$refs.addAndMoneySide.editFormVisible = false
          this.getdata()
        })
      }
    },
    //   删除
    deleteFormData(row) {
      this.$confirm(`此操作将删除此条信息记录，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteGuaranteeCompany(row.id, () => {
            this.$message.success('成功！')
            this.getdata()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 显示弹框    添加 or 编辑
    addRow(row) {
      this.$refs.addAndMoneySide.editFormVisible = true
      if (row.id) {
        this.addAndEditFormItem[0].disabled = true
        this.addAndEditFormItem[0].rules[0].required = false
        this.editFormData = row.constructor === Object ? { ...row, plantType: row.plantType + '', partner: [row.partnerProvince, row.partnerCity, row.partnerCounty] } : { status: 1 }
      } else {
        this.addAndEditFormItem[0].disabled = false
        this.addAndEditFormItem[0].rules[0].required = true
      }
    },
    // 获取列表  -- 搜索
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      obtainGuaranteeCompany(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.guaranteeCompanyBox {
  .form-search {
    /deep/.el-form-item {

      .el-form-item__label {

        width: 130px !important;
      }
    }
  }

  .cooperativeEnterprise {
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 500;
    color: #333;
  }
}
</style>

